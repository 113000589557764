import styled, { css } from "styled-components";
import {
  Tag,
  Typography,
  TagFilter,
  DataGrid,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledDataGrid = styled(DataGrid)``;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledTableText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

const StyledTag = styled(Tag)`
  cursor: auto;
  pointer-events: none;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
`;

const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex-direction: "row";
`;

const StyledFilterTag = styled(TagFilter)``;

export {
  StyledDataGrid,
  StyledText,
  StyledTableText,
  StyledTag,
  StyledDiv,
  StyledFlexRow,
  StyledFilterTag,
};

import {
  StyledDiv,
  StyledFlexColumnDiv,
  StyledFlexRowDiv,
  StyledIcon,
  StyledText,
} from "./styled";

const InfoBox = () => {
  const alert = {
    title: "Você tem cobrança(s) pendente(s)",
    text: "Caso não esteja conseguindo regularizar seu pagamento, entre em contato com nossa equipe de atendimento por e-mail: empresa@flashapp.com.br ou telefone: (11) 4118-0488.",
  };

  return (
    <StyledDiv>
      <StyledFlexRowDiv>
        <StyledIcon name="IconCircleX" fill="transparent" size={24} />
        <StyledFlexColumnDiv>
          <StyledText variant="body3" style={{ fontWeight: 600 }}>
            {alert.title}
          </StyledText>
          <StyledText variant="body4"> {alert.text}</StyledText>
        </StyledFlexColumnDiv>
      </StyledFlexRowDiv>
    </StyledDiv>
  );
};

export { InfoBox };

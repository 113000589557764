import { StyledFlexRow, StyledTableText, StyledTag } from "./styled";

export const columns = [
  {
    Header: "Referente a",
    accessor: "reference",
    disableSortBy: true,
    Cell: ({
      row: {
        original: { reference = null },
      },
    }) => {
      return <StyledTableText variant="body3">{reference}</StyledTableText>;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    disableSortBy: true,
    Cell: ({
      row: {
        original: { status = "" },
      },
    }) => {
      let tag = <div></div>;
      switch (status) {
        case "PAID":
          tag = (
            <StyledTag hasLeftDotIcon={true} variant="success">
              Pago
            </StyledTag>
          );
          break;
        case "CANCELLED":
          tag = (
            <StyledTag hasLeftDotIcon={true} variant="error">
              Cancelado
            </StyledTag>
          );
          break;
        case "PENDING_PAYMENT":
          tag = (
            <StyledTag hasLeftDotIcon={true} variant="blue">
              Em aberto
            </StyledTag>
          );
          break;
      }

      return <StyledFlexRow>{tag}</StyledFlexRow>;
    },
  },
  {
    Header: "Valor",
    accessor: "amount",
    disableSortBy: true,
    Cell: ({
      row: {
        original: { amount = null },
      },
    }) => {
      return (
        <StyledFlexRow>
          <StyledTableText variant="body3">{amount}</StyledTableText>
        </StyledFlexRow>
      );
    },
  },
  {
    Header: "Pessoas ativas",
    accessor: "activeUserCount",
    disableSortBy: true,
    Cell: ({
      row: {
        original: { activeUserCount = null },
      },
    }) => {
      return (
        <StyledFlexRow>
          <StyledTableText variant="body3">{activeUserCount}</StyledTableText>
        </StyledFlexRow>
      );
    },
  },
  {
    Header: "Data de cobrança",
    accessor: "dueDate",
    disableSortBy: true,
    Cell: ({
      row: {
        original: { dueDate = null },
      },
    }) => {
      return (
        <StyledFlexRow>
          <StyledTableText variant="body3">{dueDate}</StyledTableText>
        </StyledFlexRow>
      );
    },
  },
  {
    Header: "Ações",
    accessor: "actions",
    disableSortBy: true,
    sticky: "right",
    Cell: ({
      row: {
        original: { actions = null },
      },
    }) => {
      return actions;
    },
  },
];

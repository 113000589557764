import { Icons, Tooltip, dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider, Skeleton } from "@mui/material";
import {
  StyledBox,
  StyledDiv,
  StyledDivRow,
  StyledText,
  StyledTitle,
} from "./styled";
import { toCurrency } from "../../utils/formatter";
import { Plan } from "bff/src/common";
import { dispatchToast } from "../../utils/dispatchToast";
import { trpc } from "../../api/client";
import { useAuth } from "@flash-hros/auth-helper";
import { useState } from "react";

const planDescription = (plan: Plan): string => {
  switch (plan) {
    case Plan.control:
      return "Controle";
    case Plan.growth:
      return "Gestão";
    default:
      return "Básico";
  }
};

const SubscriptionDetailsBox = () => {
  const features = useAuth?.getState()?.expenses?.planFeatures || [];
  const [isBasicPlan, setIsBasicPlan] = useState(false);

  const { data, isLoading } = trpc.company.useQuery(undefined, {
    onError: (e) => {
      if (features?.some((feature: string) => feature === "BASIC_EXPENSE")) {
        setIsBasicPlan(true);
      } else {
        dispatchToast({
          type: "error",
          content: "Erro ao obter dados da empresa",
          description: "Tente novamente mais tarde.",
        });
      }
    },
    refetchOnWindowFocus: false,
  });

  const skeleton = <Skeleton width={"100%"} height="180px" />;

  if (isLoading) {
    return skeleton;
  }

  if (data) {
    return (
      <StyledBox>
        <StyledDiv style={{ gap: "24px" }}>
          <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
            Assinatura atual
          </StyledTitle>
          <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
            Plano {planDescription(data?.plan)}
          </StyledTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
            }}
          >
            <StyledTitle variant="body4" style={{ fontWeight: 700 }}>
              {toCurrency(data.pricePerUser.currency, data.pricePerUser.value)}
            </StyledTitle>

            <StyledText variant="body4" style={{ whiteSpace: "pre" }}>
              por usuário ativo
            </StyledText>
          </div>
        </StyledDiv>
        <>
          <Divider
            orientation="vertical"
            flexItem={true}
            style={{ marginLeft: "138px" }}
          />

          <StyledDiv style={{ gap: "24px" }}>
            <StyledDiv>
              <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                {toCurrency(data.price.currency, data.price.value)}
              </StyledTitle>
              <StyledDivRow>
                <StyledText variant="body4">
                  Valor do pacote contratado
                </StyledText>
                <Tooltip
                  title={`valor para ${
                    data.userLimit
                  } usuários ativos + \n ${toCurrency(
                    data.pricePerUser.currency,
                    data.pricePerUser.value
                  )} por usuário adicional`}
                  placement="right-start"
                >
                  <button style={{ cursor: "default" }}>
                    <Icons
                      name="IconInfoCircle"
                      fill="transparent"
                      color="#83727D"
                      size={16}
                    />
                  </button>
                </Tooltip>
              </StyledDivRow>
            </StyledDiv>
            <StyledDivRow style={{ gap: "41px" }}>
              <StyledDiv>
                <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                  {dayjs().endOf("month").format("DD/MM")}
                </StyledTitle>
                <StyledText variant="body4">Data de fechamento</StyledText>
              </StyledDiv>
              <StyledDiv>
                <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                  {data.userLimit}
                </StyledTitle>
                <StyledText variant="body4">Usuários ativos</StyledText>
              </StyledDiv>
            </StyledDivRow>
          </StyledDiv>
        </>
      </StyledBox>
    );
  }

  if (isBasicPlan) {
    return (
      <StyledBox>
        <StyledDiv style={{ gap: "24px" }}>
          <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
            Assinatura atual
          </StyledTitle>
          <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
            Plano {planDescription(Plan.free)}
          </StyledTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
            }}
          >
            <StyledTitle variant="body4" style={{ fontWeight: 700 }}>
              Gratuito
            </StyledTitle>
          </div>
        </StyledDiv>
      </StyledBox>
    );
  }

  return skeleton;
};

export { SubscriptionDetailsBox };

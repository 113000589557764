import { TagDateFilter, dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledDiv,
  StyledFilterTag,
  StyledTableText,
  StyledTag,
  StyledText,
  StyledDataGrid,
} from "./styled";
import { useState } from "react";
import { toCurrency } from "../../utils/formatter";
import { HistoricActions } from "../HistoricActions";
import { columns } from "./billingHistoricColumns";
import { trpc } from "../../api/client";
import { dispatchToast } from "../../utils/dispatchToast";
import { CustomPagination, Meta } from "./custonPagination";
import { set } from "lodash-es";

type BillRow = {
  reference: string;
  status: string;
  amount: string;
  activeUserCount: number;
  dueDate: string;
  actions: JSX.Element;
};
type BillStatus = "PENDING_PAYMENT" | "PAID" | "CANCELLED";

type DateRange = {
  from: Date | undefined;
  to?: Date | undefined;
};

type Filters = {
  status: BillStatus[];
  range?: DateRange;
};

type StatusFilter = {
  label: string;
  value: BillStatus;
};

const BillingHistoric = () => {
  const [rows, setRows] = useState<BillRow[]>([]);
  const [filters, setFilters] = useState<Filters>({ status: [] });
  const [pagination, setPagination] = useState<Meta>({
    total: 0,
    limit: 5,
    offset: 0,
    page: 1,
  });

  const { isLoading } = trpc.bill.useQuery(
    {
      status: filters?.status,
      limit: pagination.limit,
      offset: pagination.offset,
      startDate: filters.range?.from,
      endDate: filters.range?.to,
    },
    {
      onSuccess: (data) => {
        const bills = data.bills.map((bill) => {
          return {
            reference: dayjs(bill.reference).format("MMM/YYYY"),
            status: bill.status,
            amount: toCurrency(bill.amount.currency, bill.amount.value),
            activeUserCount: bill.activeUserCount,
            dueDate: dayjs(bill.dueDate).format("DD/MM/YYYY"),
            actions: (
              <HistoricActions
                activeUser={bill.activeUser}
                billetUrl={bill.billetUrl}
                nfeUrl={bill.nfeUrl}
              />
            ),
          };
        });
        setRows(bills);
        setPagination({ ...pagination, ...data.meta });
      },
      onError: (e) => {
        dispatchToast({
          type: "error",
          content: "Erro ao obter histórico de cobrança",
          description: "Tente novamente mais tarde.",
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  const filterByStatus: StatusFilter[] = [
    {
      label: "Pago",
      value: "PAID",
    },
    {
      label: "Cancelado",
      value: "CANCELLED",
    },
    {
      label: "Em aberto",
      value: "PENDING_PAYMENT",
    },
  ];

  const handleFilteredContentByStatus = (values: BillStatus[]) => {
    setFilters({ status: values });
  };

  const handleFilteredContentByReference = (range: DateRange) => {
    setFilters({ ...filters, range });
  };

  return (
    <StyledDiv>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <StyledText variant="headline8" style={{ marginRight: "4px" }}>
          Histórico de cobranças
        </StyledText>
        <StyledTag variant="gray">
          {rows?.length == 1 ? rows?.length + " item" : rows?.length + " itens"}
        </StyledTag>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <StyledTableText variant="body3">Filtrar por </StyledTableText>
        <StyledFilterTag
          hasLeftIcon={false}
          onApply={(values) => {
            handleFilteredContentByStatus(values as BillStatus[]);
          }}
          onClear={() => {
            handleFilteredContentByStatus([]);
          }}
          options={filterByStatus}
          optionIconType={"none"}
          variant={"secondary"}
          filterLabel={"Status"}
        />
        <StyledTableText variant="body3">Filtrar por </StyledTableText>
        <TagDateFilter
          hasLeftIcon={false}
          onSubmit={(values) => {
            if (values) handleFilteredContentByReference(values);
          }}
          variant={"secondary"}
          filterLabel={"Referencia"}
        />
      </div>
      <StyledDataGrid
        loading={isLoading}
        columns={columns}
        data={rows}
        pageCount={1}
      />
      <CustomPagination meta={pagination} changePagination={setPagination} />
    </StyledDiv>
  );
};

export { BillingHistoric };

import { Icons, Pagination } from "@flash-tecnologia/hros-web-ui-v2";
import { MenuItem, Select } from "@mui/material";

export type Meta = {
  total: number;
  limit: number;
  offset: number;
  page: number;
};

type PaginationProps = {
  meta: Meta;
  changePagination: (meta: Meta) => void;
};

const pageSizeOptions = [
  { label: "5 itens", value: 5 },
  { label: "10 itens", value: 10 },
  { label: "20 itens", value: 20 },
];

export const CustomPagination = ({
  meta,
  changePagination,
}: PaginationProps) => {
  return (
    <div className={`data-grid-pagination-container`}>
      <div className={`data-grid-pagination-select-container`}>
        <Select
          className="data-grid-custom-select"
          disabled={!meta.total}
          variant="standard"
          value={meta.limit}
          IconComponent={(props) => (
            <div className="select-icon-custom">
              <Icons name="IconChevronDown" fill="transparent" {...props} />
            </div>
          )}
          onChange={(e) => {
            changePagination({
              ...meta,
              limit: Number(e?.target?.value),
            });
          }}
        >
          {pageSizeOptions?.map((opt) => (
            <MenuItem key={opt?.value} value={opt?.value}>
              {opt?.label}
            </MenuItem>
          ))}
        </Select>
        <div className="data-grid-custom-pagination-text">
          Resultados:&nbsp;{meta.offset + 1}-
          {meta.total - meta.offset > meta.limit
            ? meta.offset + meta.limit
            : meta.total - meta.offset}
          &nbsp;de&nbsp;
          {meta.total}&nbsp;
          {meta.total - meta.offset === 1 ? "item" : "itens"}
        </div>
      </div>
      <div className={`data-grid-pagination-number-container`}>
        <Pagination
          page={meta.page}
          count={Math.ceil(meta.total / meta.limit)}
          onChange={(e, page) =>
            changePagination({
              ...meta,
              offset: meta.limit * (page - 1),
              page,
            })
          }
        />
      </div>
    </div>
  );
};

import { trpc } from "../../api/client";
import {
  StyledBox,
  StyledTitle,
  StyledText,
  StyledDiv,
  StyledTextSecundary,
  StyledIcon,
} from "./styled";
import { dispatchToast } from "../../utils/dispatchToast";
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { Skeleton } from "@mui/material";

const PaymentBox = () => {
  const { isLoading, data } = trpc.getLastBill.useQuery(undefined, {
    onError: (e) => {
      dispatchToast({
        type: "error",
        content: "Erro ao obter ultima cobrança",
        description: "Tente novamente mais tarde.",
      });
    },
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <Skeleton width={"100%"} height={"180px"} />;
  }

  return (
    <StyledBox>
      <StyledDiv style={{ gap: "24px" }}>
        <StyledIcon name="IconCashBanknote" fill="transparent" size={18} />
        {data && (
          <>
            <div>
              <StyledTitle
                variant="body3"
                style={{ fontWeight: 700, marginBottom: 5 }}
              >
                Meio de pagamento atual
              </StyledTitle>
              <StyledText variant="body3">
                {data?.payment.type === "BILLET"
                  ? `Boleto (vencimento ${dayjs(data.dueDate).format(
                      "MMM/YYYY"
                    )})`
                  : ""}
              </StyledText>
            </div>
            <a href={data?.payment.link} target="_blank">
              <StyledTextSecundary
                variant="body3"
                style={{ fontWeight: 700, cursor: "pointer" }}
              >
                Baixar boleto
              </StyledTextSecundary>
            </a>
          </>
        )}
      </StyledDiv>
    </StyledBox>
  );
};

export { PaymentBox };

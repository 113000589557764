// @filename: client.ts
import { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "bff";
import { ENV } from "../config";
import superjson from "superjson";
import { getAccessToken } from "@flash-hros/utility";

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient();
export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      headers: async () => {
        const token = await getAccessToken();
        return { Authorization: `Bearer ${token}` };
      },
      url: ENV.SERVER_URL,
    }),
  ],
  transformer: superjson,
});

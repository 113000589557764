import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledBox = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 24px;
  height: 100%;

  border: 1px solid #ebe6e9;
  border-radius: 8px;
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledSecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDivRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export {
  StyledBox,
  StyledTitle,
  StyledSecondary,
  StyledText,
  StyledDiv,
  StyledDivRow,
};

import { useTranslation } from "react-i18next";
import { trpc } from "../api/client";
import { SubscriptionDetailsBox } from "../components/SubscriptionDetailsBox";
import { PaymentBox } from "../components/PaymentBox/PaymentBox";
import { Grid } from "@mui/material";
import { BillingHistoric } from "../components/BillingHistoric";
import { dispatchToast } from "../utils/dispatchToast";
import { InfoBox } from "../components/InfoBox";
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";

const Page = () => {
  const { data: bill } = trpc.getLastBill.useQuery(
    { status: "PENDING_PAYMENT", sort: "dueDate.asc" },
    {
      refetchOnWindowFocus: false,
    }
  );
  const today = dayjs().format("YYYY-MM-DD");
  const expiredBillet = dayjs(bill?.dueDate).isBefore(today);

  return (
    <Grid container spacing={2}>
      {expiredBillet && (
        <>
          <Grid item md={12}>
            <InfoBox />
          </Grid>
        </>
      )}
      <Grid item md={9}>
        <SubscriptionDetailsBox />
      </Grid>
      <Grid item md={3}>
        <PaymentBox />
      </Grid>
      <Grid item md={12} style={{ marginTop: "40px" }}>
        <BillingHistoric />
      </Grid>
    </Grid>
  );
};

export default Page;

import { Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { IconDots, StyledText } from "./styled";
import { utils, writeFileXLSX } from "xlsx";
import { User } from "../../types";

interface IActionsData {
  activeUser: User[];
  nfeUrl: string;
  billetUrl: string;
}

const jsonToXlsx = (data: User[]) => {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFileXLSX(workbook, "usuarios_ativos.xlsx");
};

const HistoricActions = ({ activeUser, nfeUrl, billetUrl }: IActionsData) => {
  const options = [
    {
      children: <StyledText variant="body4">Ver usuários ativos</StyledText>,
      onClick: () => {
        jsonToXlsx(activeUser);
      },
    },
    {
      children: <StyledText variant="body4">Baixar boleto</StyledText>,
      onClick: () => {
        window.open(billetUrl, "_blank", "noreferrer");
      },
    },
    {
      children: <StyledText variant="body4">Ver nota fiscal</StyledText>,
      onClick: () => {
        window.open(nfeUrl, "_blank", "noreferrer");
      },
    },
  ];

  return (
    <Menu
      type="default"
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      options={options}
    >
      <IconDots />
    </Menu>
  );
};

export { HistoricActions };

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledBox = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  height: 100%;

  border: 1px solid #ebe6e9;
  border-radius: 8px;
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

const StyledTextSecundary = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled(Icons)`
  background-color: #ffe0ef;
  color: #fe2b8f;
  border-radius: 42px;
  height: 42px;
  width: 42px;
  padding: 12px;
`;

export {
  StyledBox,
  StyledTitle,
  StyledText,
  StyledDiv,
  StyledTextSecundary,
  StyledIcon,
};
